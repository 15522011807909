import React, { useState, useEffect, useRef } from 'react';
import { usePlayer, useGame } from "@empirica/core/player/classic/react";
import { Box, Typography, Container } from '@mui/material';
import { IntroStepper } from "../components/Stepper";
import { UserExperience } from "./exit-elements/UserExperience";
import { UserRating } from "./exit-elements/UserRating";
import { RewardPage } from "./exit-elements/RewardPage";
import { useOverallExperience, useAIEvaluation, useOpenFeedback, allFieldsAssignedOverallExperience, allFieldsAssignedAIEvaluation, useUserRating } from "./exit-elements/customHooksExit";

export function ExitSurvey({ next }) {
  const player = usePlayer();
  const game = useGame();
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['User Experience', 'User Rating', 'Exit (Reward)'];
  const overallExperience = useOverallExperience();
  const [allAnswered, setAllAnswered] = useState(false);
  const aiEvaluation = useAIEvaluation();
  const openFeedback = useOpenFeedback();
  const userRating = useUserRating();

  const containerRef = useRef(null);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = () => {
    const surveyData = {
      ...overallExperience,
      ...aiEvaluation,
      ...openFeedback,
      ...userRating
    };
    player.set("exitSurvey", surveyData);
    next();
  };

  const isStepValid = () => {
    switch (activeStep) {
      case 0:
        return allFieldsAssignedOverallExperience(overallExperience) && allFieldsAssignedAIEvaluation(aiEvaluation);
      // return true;
      case 1:
        return allAnswered;
      case 2:
        return true;
      default:
        return true;
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <UserExperience
            overallExperience={overallExperience}
            aiEvaluation={aiEvaluation}
            openFeedback={openFeedback}
          />
        );
      case 1:
        return <UserRating userRating={userRating} onAllAnsweredChange={setAllAnswered} />;
      case 2:
        return <RewardPage />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <Container
      ref={containerRef}
      style={{
        minHeight: 'calc(100vh - 80px)',
        width: '100%',
        maxWidth: '1200px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        position: 'fixed',
        top: '1px',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '20px',
      }}
    >
      <Box className="py-8 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <IntroStepper
          activeStep={activeStep}
          steps={steps}
          handleNext={handleNext}
          handleBack={handleBack}
          handleFinish={handleFinish}
          isStepValid={isStepValid()}
        >
          <Box component="form" className="mt-8 space-y-8 divide-y divide-gray-400">
            {getStepContent(activeStep)}
          </Box>
        </IntroStepper>
      </Box>
    </Container>
  );
}