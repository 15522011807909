export const handleLike = (index, posts, setPosts, player) => {
    const newPosts = [...posts];
    const playerScore = player.get("score") || 0;
    if (newPosts[index].likedByUser) {
        newPosts[index].likes--;
        newPosts[index].likedByUser = false;
        player.set("score", playerScore - 1);
    } else {
        newPosts[index].likes++;
        newPosts[index].likedByUser = true;
        player.set("score", playerScore + 1);
    }
    setPosts(newPosts);
};

export const handleAddComment = (index, comment, user, posts, setPosts, player, commentImages, setCommentImages) => {
    const playerScore = player.get("score") || 0;
    if (comment.trim() !== '' || commentImages[`${index}-null-null`]) {
        const newPosts = [...posts];
        newPosts[index].comments.push({
            user: user,
            text: comment,
            images: commentImages[`${index}-null-null`] || [], // Ensure images is an array
            likes: 0,
            likedByUser: false,
            replies: []
        });
        setPosts(newPosts);
        setCommentImages((prev) => ({
            ...prev,
            [`${index}-null-null`]: null,
        }));
        player.set("score", playerScore + 1);
    }
};

export const handleAddReply = (postIndex, commentIndex, reply, user, posts, setPosts, player) => {
    const playerScore = player.get("score") || 0;
    if (reply.trim() !== '') {
        const newPosts = [...posts];
        newPosts[postIndex].comments[commentIndex].replies.push({
            user: user,
            text: reply,
            images: [], // Ensure images is an array
            likes: 0,
            likedByUser: false
        });
        setPosts(newPosts);
        player.set("score", playerScore + 1);
    }
};

export const handleLikeComment = (postIndex, commentIndex, posts, setPosts, player) => {
    const newPosts = [...posts];
    const comment = newPosts[postIndex].comments[commentIndex];
    const playerScore = player.get("score") || 0;
    if (comment.likedByUser) {
        comment.likes--;
        comment.likedByUser = false;
        player.set("score", playerScore - 1);
    } else {
        comment.likes++;
        comment.likedByUser = true;
        player.set("score", playerScore + 1);
    }
    setPosts(newPosts);
};

export const handleCommentButtonClick = (index, commentInputRefs, player, posts, setPosts, commentImages, setCommentImages) => {
    const commentInput = commentInputRefs.current[index];
    if (commentInput) {
        handleAddComment(index, commentInput.value, player.id, posts, setPosts, player, commentImages, setCommentImages);
        commentInput.value = '';
    }
};

export const handleReplyButtonClick = (postIndex, commentIndex, replyInputRefs, player, posts, setPosts) => {
    const replyInput = replyInputRefs.current[commentIndex];
    if (replyInput && replyInput.value.trim() !== '') {
        handleAddReply(postIndex, commentIndex, replyInput.value, player.id, posts, setPosts, player);
        replyInput.value = ''; // Clear the input after submitting
    }
};