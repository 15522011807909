import { useState } from "react";
import { questionsKnowledgeAssessment } from "./questions";

function useDemographicInfo() {
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [education, setEducation] = useState("");
    const [occupation, setOccupation] = useState([]);
    const [partyAffiliation, setPartyAffiliation] = useState("");
    const [politicalParticipation, setPoliticalParticipation] = useState("");
    const [otherOccupation, setOtherOccupation] = useState('');

    return {
        age, setAge,
        gender, setGender,
        education, setEducation,
        occupation, setOccupation,
        partyAffiliation, setPartyAffiliation,
        politicalParticipation, setPoliticalParticipation,
        otherOccupation, setOtherOccupation
    };
}

function allFieldsAssignedDemographicInfo({
    age,
    gender,
    education,
    occupation,
    partyAffiliation,
    politicalParticipation,
    otherOccupation
}) {
    const otherFieldsValid = !(
        (occupation.includes("Other (please specify)") && otherOccupation === "")
    );

    return (
        age !== "" &&
        gender !== "" &&
        education !== "" &&
        occupation.length > 0 &&
        partyAffiliation !== "" &&
        politicalParticipation !== "" &&
        otherFieldsValid
    );
}

function useSocialMediaInfo() {
    const [socialMediaFrequency, setSocialMediaFrequency] = useState("");
    const [socialMediaPlatforms, setSocialMediaPlatforms] = useState([]);
    const [engagementFrequency, setEngagementFrequency] = useState({
        "Climate change": "",
        "US politics": "",
        "Science": ""
    });
    const [socialInfluence, setSocialInfluence] = useState("");
    const [conformity, setConformity] = useState("");
    const [credibility, setCredibility] = useState("");
    const [trust, setTrust] = useState("");
    const [otherPlatform, setOtherPlatform] = useState('');
    const [otherActivity, setOtherActivity] = useState('');
    const [otherContent, setOtherContent] = useState('');

    return {
        socialMediaFrequency, setSocialMediaFrequency,
        socialMediaPlatforms, setSocialMediaPlatforms,
        engagementFrequency, setEngagementFrequency,
        otherPlatform, setOtherPlatform,
        otherActivity, setOtherActivity,
        otherContent, setOtherContent,
        socialInfluence, setSocialInfluence,
        conformity, setConformity,
        credibility, setCredibility,
        trust, setTrust
    };
}

function allFieldsAssignedSocialMediaInfo({
    socialMediaFrequency,
    socialMediaPlatforms,
    preferredContent,
    engagementFrequency,
    otherPlatform,
    socialInfluence,
    conformity,
    credibility,
    trust
}) {
    const otherFieldsValid = !(
        (socialMediaPlatforms.includes("Other (please specify)") && otherPlatform === "")
    );

    return (
        socialMediaFrequency !== "" &&
        socialMediaPlatforms.length > 0 &&
        socialInfluence !== "" &&
        conformity !== "" &&
        credibility !== "" &&
        trust !== "" &&
        Object.values(engagementFrequency).every(value => value !== "") &&
        otherFieldsValid
    );
}

function useKnowledgeAssessment() {
    const initialAssessment = questionsKnowledgeAssessment.reduce((acc, _, index) => {
        acc[`question${index + 11}`] = "";
        return acc;
    }, {});

    const [knowledgeAssessment, setKnowledgeAssessment] = useState(initialAssessment);
    const [topicsOfInterest, setTopicsOfInterest] = useState([]);
    const [otherTopic, setOtherTopic] = useState('');

    return {
        knowledgeAssessment, setKnowledgeAssessment,
        topicsOfInterest, setTopicsOfInterest,
        otherTopic, setOtherTopic
    };
}

function allFieldsAssignedKnowledgeAssessment({
    knowledgeAssessment,
    topicsOfInterest,
    otherTopic
}) {
    const allQuestionsAnswered = Object.values(knowledgeAssessment).every(value => value !== "");
    const topicsValid = topicsOfInterest.length > 0 && (!topicsOfInterest.includes("Other (please specify)") || otherTopic !== "");

    return allQuestionsAnswered && topicsValid;
}

export { useDemographicInfo, useSocialMediaInfo, useKnowledgeAssessment, allFieldsAssignedSocialMediaInfo, allFieldsAssignedKnowledgeAssessment, allFieldsAssignedDemographicInfo };