import React, { useState, useEffect } from 'react';
import { ImageWrapper, Modal, ModalContent, CloseButton } from './SocialMediaStyles';

const ImagePreview = ({ src }) => {
    const [isFullSize, setIsFullSize] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setIsFullSize(false);
            }
        };

        if (isFullSize) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isFullSize]);

    return (
        <>
            <ImageWrapper onClick={() => setIsFullSize(true)}>
                <img
                    src={src}
                    alt="Post"
                    style={{
                        width: '100%',
                        height: '110px',
                        objectFit: 'cover',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginTop: '10px'
                    }}
                />
            </ImageWrapper>
            <Modal $isOpen={isFullSize}>
                <ModalContent style={{ position: 'relative' }}>
                    <CloseButton onClick={() => setIsFullSize(false)} style={{ position: 'absolute', top: '10px', right: '10px' }}>
                        &times;
                    </CloseButton>
                    <img
                        src={src}
                        alt="Full-size"
                        style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'contain'
                        }}
                    />
                </ModalContent>
            </Modal>
        </>
    );
};

export default ImagePreview;