import { useState } from "react";

function useUserEvaluation() {
    const [topicRelevance, setTopicRelevance] = useState("");
    const [interpersonalSatisfaction, setInterpersonalSatisfaction] = useState("");
    const [engagement, setEngagement] = useState("");
    const [otherParticipantsEngagement, setOtherParticipantsEngagement] = useState("");

    return {
        topicRelevance, setTopicRelevance,
        interpersonalSatisfaction, setInterpersonalSatisfaction,
        engagement, setEngagement,
        otherParticipantsEngagement, setOtherParticipantsEngagement
    };
}

function useOverallExperience() {
    const [overallExperience, setOverallExperience] = useState("");
    const [informativeness, setInformativeness] = useState("");
    const [trustOtherParticipants, setTrustOtherParticipants] = useState("");
    const [trustInformation, setTrustInformation] = useState("");
    const [satisfactionContent, setSatisfactionContent] = useState("");
    const [confortability, setConfortability] = useState("");
    const [confortabilityOpinion, setConfortabilityOpinion] = useState("");
    const [enoughTime, setEnoughTime] = useState("");

    return {
        overallExperience, setOverallExperience,
        informativeness, setInformativeness,
        trustOtherParticipants, setTrustOtherParticipants,
        trustInformation, setTrustInformation,
        satisfactionContent, setSatisfactionContent,
        confortability, setConfortability,
        confortabilityOpinion, setConfortabilityOpinion,
        enoughTime, setEnoughTime
    };
}

function useAIEvaluation() {
    const [aiHelpfulness, setAiHelpfulness] = useState("");
    const [aiNaturalness, setAiNaturalness] = useState("");
    const [aiParticipation, setAiParticipation] = useState("");
    const [aiToxicity, setAiToxicity] = useState("");
    const [aiPositivity, setAiPositivity] = useState("");
    const [aiMisinformation, setAiMisinformation] = useState("");
    const [aiPolitics, setAiPolitics] = useState("");

    return {
        aiHelpfulness, setAiHelpfulness,
        aiNaturalness, setAiNaturalness,
        aiParticipation, setAiParticipation,
        aiToxicity, setAiToxicity,
        aiPositivity, setAiPositivity,
        aiMisinformation, setAiMisinformation,
        aiPolitics, setAiPolitics
    };
}

function useOpenFeedback() {
    const [feedbackPlatform, setFeedbackPlatform] = useState("");
    const [feedbackAI, setFeedbackAI] = useState("");

    return {
        feedbackPlatform, setFeedbackPlatform,
        feedbackAI, setFeedbackAI
    };
}

function allFieldsAssignedOverallExperience({
    overallExperience,
    informativeness,
    trustOtherParticipants,
    trustInformation,
    satisfactionContent,
    confortability,
    confortabilityOpinion,
    enoughTime
}) {
    return (
        overallExperience !== "" &&
        informativeness !== "" &&
        trustOtherParticipants !== "" &&
        trustInformation !== "" &&
        satisfactionContent !== "" &&
        confortability !== "" &&
        confortabilityOpinion !== "" &&
        enoughTime !== ""
    );
}

function allFieldsAssignedAIEvaluation({
    aiHelpfulness,
    aiNaturalness,
    aiParticipation,
    aiToxicity,
    aiPositivity,
    aiMisinformation,
    aiPolitics
}) {
    return (
        aiHelpfulness !== "" &&
        aiNaturalness !== "" &&
        aiParticipation !== "" &&
        aiToxicity !== "" &&
        aiPositivity !== "" &&
        aiMisinformation !== "" &&
        aiPolitics !== ""
    );
}

function useUserRating() {
    const [commentRatings, setCommentRatings] = useState({});
    const [participantRatings, setParticipantRatings] = useState({});

    return {
        commentRatings, setCommentRatings,
        participantRatings, setParticipantRatings
    };
}

export { useUserEvaluation, useOverallExperience, useAIEvaluation, useOpenFeedback, allFieldsAssignedOverallExperience, allFieldsAssignedAIEvaluation, useUserRating };