import React, { useEffect } from "react";
import { Box, Grid, Typography, TextField } from "@mui/material";
import { SingleCategoricalQuestion } from "../../components/Questionaire/SingleCategoricalQuestion";
import { MultipleSelectionQuestion } from "../../components/Questionaire/MultipleSelectionQuestion";

export function DemographicInformation({ age, setAge, gender, setGender, education, setEducation, occupation, setOccupation, partyAffiliation, setPartyAffiliation, politicalParticipation, setPoliticalParticipation, handleCheckboxChange, otherOccupation, setOtherOccupation }) {

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h5" component="h3" gutterBottom>
                🌍 Demographic Information
            </Typography>
            <Typography component="div" sx={{ mt: 1 }}>
                In this page and the following pages, we just want to know you a little bit before we start the experiment. If you feel uncomfortable with answering any of these questions, please just select "Prefer not to answer".
            </Typography>

            <Grid container spacing={5} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="What is your age group?"
                        name="age"
                        value={age}
                        options={["Under 18", "18-24", "25-34", "35-44", "45-54", "55-64", "65+", "Prefer not to answer"]}
                        onChange={(e) => setAge(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <SingleCategoricalQuestion
                        label="How do you identify your gender?"
                        name="gender"
                        value={gender}
                        options={["Male", "Female", "Non-binary", "Prefer not to answer"]}
                        onChange={(e) => setGender(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <SingleCategoricalQuestion
                        label="What is the highest level of education you have completed?"
                        name="education"
                        value={education}
                        options={["Did not graduate from high school", "High school graduate", "Some college but no degree", "2-year college degree", "4-year college degree", "Postgraduate degree (MA, MBA, JD, PhD, etc)", "Prefer not to answer"]}
                        onChange={(e) => setEducation(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <MultipleSelectionQuestion
                        label="What is your current occupation or employment status?"
                        name="occupation"
                        values={occupation}
                        options={["Student", "Employed full-time", "Employed part-time", "Self-employed", "Unemployed", "Retired", "Other (please specify)", "Prefer not to answer"]}
                        onChange={handleCheckboxChange(setOccupation)}
                        otherValue={otherOccupation}
                        setOtherValue={setOtherOccupation}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <SingleCategoricalQuestion
                        label="Which of the following best describes your political party affiliation or the political group you most closely align with? Please choose the option that best reflects your stance, even if you don’t fully agree with everything that party stands for."
                        name="partyAffiliation"
                        value={partyAffiliation}
                        options={["Strong Democrat", "Moderate Democrat", "Independent", "Moderate Republican", "Strong Republican", "Other (Libertarian, Green Party, etc.)", "Prefer not to answer"]}
                        onChange={(e) => setPartyAffiliation(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <SingleCategoricalQuestion
                        label="How often do you engage in political activities, such as voting, attending rallies, or participating in campaigns?"
                        name="politicalParticipation"
                        value={politicalParticipation}
                        options={["Never", "Rarely", "Sometimes", "Often", "Always", "Prefer not to answer"]}
                        onChange={(e) => setPoliticalParticipation(e.target.value)}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}