import React, { useState, useEffect, useRef } from 'react';
import { useGame, usePlayer, usePlayers, useRound } from "@empirica/core/player/classic/react";
import { Avatar } from '../components/Avatar'; // Import the Avatar component
import { formatPostForLLM } from '../utils/formatPostForLLM';
import { FaRegHeart, FaRegComment, FaComment } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import { BsReply, BsFillReplyAllFill } from "react-icons/bs";
import {
  Post, PostContent, PostText, PostActions, Button, CommentsSection, CommentInput,
  CommentsList, Comment, CommentText, AvatarWrapper, CommentActions, SmallButton,
  StatText, ButtonAlignment, Reply, ReplyText, ImagePreviewContainer, ImageWrapper,
  Modal, ModalContent, CloseButton
} from '../components/SocialMediaStyles';
import {
  handleLike, handleAddComment, handleAddReply, handleLikeComment, handleCommentButtonClick, handleReplyButtonClick
} from '../utils/SocialMediaHandlers';
import ImagePreview from '../components/ContentPreview';
import { CommentSection } from '../components/Suggestions'; // Import CommentSection
import ReactionButton from '../components/ReactionButton';

export function SocialMedia() {
  const player = usePlayer();
  const players = usePlayers();
  const round = useRound();
  const partner = players.filter((p) => p.id !== player.id)[0];
  const commentInputRefs = useRef([]);
  const replyInputRefs = useRef([]);
  const [commentImages, setCommentImages] = useState({});
  const game = useGame();
  const { chatEnabled, aiAssistant, suggestionsEnabled } = game.get("treatment");

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('example.json')
      .then(response => response.json())
      .then(data => {
        const index = round.get('name');
        if (index !== undefined && index < data.length) {
          setPosts([data[index]]);
          round.set("post_content", formatPostForLLM(data[index], player.id)); // Set post content when data is loaded
        } else {
          console.error('Invalid index:', index);
        }
      })
      .catch(error => console.error('Error fetching posts:', error));
  }, [round, player.id]);

  useEffect(() => {
    if ((chatEnabled || aiAssistant) && posts.length > 0) {
      round.set("post_content", formatPostForLLM(posts[0], player.id));
    }
  }, [chatEnabled, aiAssistant, posts, player.id, round]);

  useEffect(() => {
    if (posts.length > 0) {
      round.set("post_content", formatPostForLLM(posts[0], player.id)); // Set post content whenever posts change
    }
  }, [posts, player.id, round]);

  return (
    <div className="flex flex-col" style={{ width: '100%', maxWidth: '800px', position: 'fixed', top: '80px', bottom: '0', overflowY: 'auto' }}>
      {posts.map((post, index) => (
        <Post key={index}>
          <PostContent>
            <AvatarWrapper>
              <Avatar user={post.user} />
              <strong>{post.user} </strong>
            </AvatarWrapper>
            <PostText>{post.content}</PostText>
            <ImagePreviewContainer>
              {post.images.map((image, imgIndex) => (
                <ImagePreview key={imgIndex} src={image} />
              ))}
            </ImagePreviewContainer>
          </PostContent>
          <PostActions>
            <Button onClick={() => handleLike(index, posts, setPosts, player)}>
              <ButtonAlignment>
                {post.likedByUser ? <FaHeart /> : <FaRegHeart />} <StatText>{post.likes}</StatText>
              </ButtonAlignment>
            </Button>
            <ReactionButton />
            <Button onClick={() => handleCommentButtonClick(index, commentInputRefs, player, posts, setPosts, commentImages, setCommentImages)}>
              <ButtonAlignment>
                {post.comments.length > 0 ? <FaComment /> : <FaRegComment />}
                <StatText>{post.comments.length}</StatText>
              </ButtonAlignment>
            </Button>
          </PostActions>
          <CommentsSection>
            {suggestionsEnabled ? (
              <CommentSection
                onSubmitComment={(commentText) => handleAddComment(index, commentText, player.id, posts, setPosts, player, commentImages, setCommentImages)}
                specificPart={post.content} // Pass the post content as specificPart
              />
            ) : (
              <CommentInput
                type="text"
                placeholder="Write a comment..."
                ref={(el) => commentInputRefs.current[index] = el}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddComment(index, e.target.value, player.id, posts, setPosts, player, commentImages, setCommentImages);
                    e.target.value = '';
                  }
                }}
              />
            )}
            <CommentsList>
              {post.comments.map((comment, commentIndex) => (
                <Comment key={commentIndex}>
                  <CommentText>
                    <AvatarWrapper>
                      <Avatar player={comment.user} size="20px" />
                    </AvatarWrapper>
                    <strong>{comment.user} </strong>
                    <p>{comment.text}</p>
                  </CommentText>
                  <ImagePreviewContainer>
                    {comment.images.map((image, imgIndex) => (
                      <ImagePreview key={imgIndex} src={image} />
                    ))}
                  </ImagePreviewContainer>
                  <CommentActions>
                    <SmallButton onClick={() => handleLikeComment(index, commentIndex, posts, setPosts, player)}>
                      {comment.likedByUser ? <FaHeart /> : <FaRegHeart />}
                      <StatText>{comment.likes}</StatText>
                    </SmallButton>
                    <SmallButton onClick={() => handleReplyButtonClick(index, commentIndex, replyInputRefs, player, posts, setPosts)}>
                      {comment.replies.length > 0 ? <BsFillReplyAllFill /> : <BsReply />}
                      <StatText>{comment.replies.length}</StatText>
                    </SmallButton>
                  </CommentActions>
                  {suggestionsEnabled ? (
                    <CommentSection
                      onSubmitComment={(replyText) => handleAddReply(index, commentIndex, replyText, player.id, posts, setPosts, player)}
                      specificPart={comment.text} // Pass the comment text as specificPart for replies
                    />
                  ) : (
                    <CommentInput
                      ref={(el) => (replyInputRefs.current[commentIndex] = el)}
                      placeholder="Add a reply..."
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleAddReply(index, commentIndex, e.target.value, player.id, posts, setPosts, player);
                          e.target.value = '';
                        }
                      }}
                    />
                  )}
                  {comment.replies.map((reply, replyIndex) => (
                    <Reply key={replyIndex}>
                      <ReplyText>
                        <AvatarWrapper>
                          <Avatar player={reply.user} size="20px" />
                        </AvatarWrapper>
                        <strong>{reply.user} </strong>
                        <p>{reply.text}</p>
                      </ReplyText>
                    </Reply>
                  ))}
                </Comment>
              ))}
            </CommentsList>
          </CommentsSection>
        </Post>
      ))}
      <div className="flex justify-center mb-4">
        <Button onClick={() => {
          player.stage.set("data", posts);
          player.stage.set("submit", true);
        }}>
          Continue
        </Button>
      </div>
    </div>
  );
}