import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { SingleCategoricalQuestion } from "../../components/Questionaire/SingleCategoricalQuestion";
import { MultipleSelectionQuestion } from "../../components/Questionaire/MultipleSelectionQuestion";
import { questionsKnowledgeAssessment } from "./questions";

export function KnowledgeAssessment({ knowledgeAssessment, setKnowledgeAssessment, topicsOfInterest, setTopicsOfInterest, otherTopic, setOtherTopic, handleCheckboxChange }) {

    const likertScaleOptions = ["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree", "Prefer not to answer"];

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h5" component="h3" gutterBottom>
                🧠 Topic Interests and Opinions
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
                This is the last page before the experiment starts, we promise! The questions here are meant to get an idea of what you are interested in and what you think about certain topics.
            </Typography>
            <Grid container spacing={3} sx={{ mt: 3 }}>
                <Grid item xs={12}>
                    <MultipleSelectionQuestion
                        label="Which topics are you most interested in? (Select all that apply)"
                        name="topicsOfInterest"
                        values={topicsOfInterest}
                        options={["Technology", "Science", "Health", "Finance", "Education", "Entertainment", "Politics", "Sports", "Environment", "Other (please specify)"]}
                        onChange={handleCheckboxChange(setTopicsOfInterest)}
                        otherValue={otherTopic}
                        setOtherValue={setOtherTopic}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h7" component="h3" gutterBottom sx={{ mt: 3, mb: 1 }}>
                        For the questions below, please select how much you agree with the statements.
                    </Typography>
                </Grid>
                {questionsKnowledgeAssessment.map((question, index) => (

                    <Grid item xs={12} key={index}>
                        <SingleCategoricalQuestion
                            label={question}
                            name={`question${index + 11}`}
                            value={knowledgeAssessment[`question${index + 11}`]}
                            options={likertScaleOptions}
                            onChange={(e) => setKnowledgeAssessment({ ...knowledgeAssessment, [`question${index + 11}`]: e.target.value })}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}