import React, { useEffect } from 'react';
import { usePlayer } from "@empirica/core/player/classic/react";
import { SingleCategoricalQuestion } from "../../components/Questionaire/SingleCategoricalQuestion";
import { Box, Typography, TextField, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Grid } from '@mui/material';

export function UserExperience({ overallExperience, aiEvaluation, openFeedback }) {

    return (
        <Box sx={{ maxWidth: 1000, margin: 'auto', p: 3, mb: 6 }}>
            <Typography variant="h4" gutterBottom>Experience on the platform</Typography>
            <Typography variant="body1" gutterBottom sx={{ mb: 4 }}>
                Thank you for participating in this experiment. On this page, we would like to know about your experience on the platform.
            </Typography>
            <Grid container spacing={5} sx={{ mt: 3 }}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Overall Experience
                    </Typography>
                    <SingleCategoricalQuestion
                        label="How would you rate your overall experience with the platform?"
                        name="overallExperience"
                        value={overallExperience.overallExperience}
                        options={["Very Poor", "Poor", "Fair", "Good", "Excellent", "Prefer not to answer"]}
                        onChange={(e) => overallExperience.setOverallExperience(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="Overall, how informative was your experience on the platform?"
                        name="informativeness"
                        value={overallExperience.informativeness}
                        options={["Not at all informative", "Slightly informative", "Moderately informative", "Very informative", "Extremely informative", "Prefer not to answer"]}
                        onChange={(e) => overallExperience.setInformativeness(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="How much did you trust other participants?"
                        name="trustOtherParticipants"
                        value={overallExperience.trustOtherParticipants}
                        options={["Not at all", "Slightly", "Moderately", "Very", "Extremely", "Prefer not to answer"]}
                        onChange={(e) => overallExperience.setTrustOtherParticipants(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="How much did you trust the information provided by the other participants?"
                        name="trustInformation"
                        value={overallExperience.trustInformation}
                        options={["Not at all", "Slightly", "Moderately", "Very", "Extremely", "Prefer not to answer"]}
                        onChange={(e) => overallExperience.setTrustInformation(e.target.value)}
                    />
                </Grid>


                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="How satisfied were you with the content generated by the other participants?"
                        name="satisfactionContent"
                        value={overallExperience.satisfactionContent}
                        options={["Very Dissatisfied", "Dissatisfied", "Neutral", "Satisfied", "Very Satisfied", "Prefer not to answer"]}
                        onChange={(e) => overallExperience.setSatisfactionContent(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="How comfortable did you feel participating in the discussion?"
                        name="confortability"
                        value={overallExperience.confortability}
                        options={["Not at all comfortable", "Slightly comfortable", "Moderately comfortable", "Very comfortable", "Extremely comfortable", "Prefer not to answer"]}
                        onChange={(e) => overallExperience.setConfortability(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="How comfortable did you feel sharing your opinion?"
                        name="confortabilityOpinion"
                        value={overallExperience.confortabilityOpinion}
                        options={["Not at all comfortable", "Slightly comfortable", "Moderately comfortable", "Very comfortable", "Extremely comfortable", "Prefer not to answer"]}
                        onChange={(e) => overallExperience.setConfortabilityOpinion(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="Did you feel like you had enough time on each conversation?"
                        name="enoughTime"
                        value={overallExperience.enoughTime}
                        options={["Not at all", "Would like a bit more time", "It was sufficient", "Would like a bit less time", "It was way too much time", "Prefer not to answer"]}
                        onChange={(e) => overallExperience.setEnoughTime(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" component="h3" gutterBottom>
                        AI Evaluation
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ mb: 4, fontStyle: 'italic' }}>
                        If you didn't see any AI tools, please select "Not applicable".
                    </Typography>
                    <SingleCategoricalQuestion
                        label="How helpful were the AI interventions in facilitating discussion?"
                        name="aiHelpfulness"
                        value={aiEvaluation.aiHelpfulness}
                        options={["Not at all helpful", "Slightly helpful", "Moderately helpful", "Very helpful", "Extremely helpful", "Prefer not to answer", "Not applicable"]}
                        onChange={(e) => aiEvaluation.setAiHelpfulness(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="How natural did the AI interventions feel in the context of the discussion?"
                        name="aiNaturalness"
                        value={aiEvaluation.aiNaturalness}
                        options={["Very unnatural", "Somewhat unnatural", "Neutral", "Somewhat natural", "Very natural", "Prefer not to answer", "Not applicable"]}
                        onChange={(e) => aiEvaluation.setAiNaturalness(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom sx={{ mt: 5 }}>
                        For the questions below, please rate how much you agree with the satements.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="The AI intervention would make me more likely to participate in online discussions."
                        name="aiParticipation"
                        value={aiEvaluation.aiParticipation}
                        options={["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree", "Prefer not to answer", "Not applicable"]}
                        onChange={(e) => aiEvaluation.setAiParticipation(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="The AI intervention would make online discussions less toxic."
                        name="aiToxicity"
                        value={aiEvaluation.aiToxicity}
                        options={["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree", "Prefer not to answer", "Not applicable"]}
                        onChange={(e) => aiEvaluation.setAiToxicity(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="The AI intervention would make online discussions more positive."
                        name="aiPositivity"
                        value={aiEvaluation.aiPositivity}
                        options={["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree", "Prefer not to answer", "Not applicable"]}
                        onChange={(e) => aiEvaluation.setAiPositivity(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="The AI intervention would reduce the amount of misinformation on social media."
                        name="aiMisinformation"
                        value={aiEvaluation.aiMisinformation}
                        options={["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree", "Prefer not to answer", "Not applicable"]}
                        onChange={(e) => aiEvaluation.setAiMisinformation(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="The AI intervention would make political discussions less polarizing on social media."
                        name="aiMisinformation"
                        value={aiEvaluation.aiPolitics}
                        options={["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree", "Prefer not to answer", "Not applicable"]}
                        onChange={(e) => aiEvaluation.setAiPolitics(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" component="h3" gutterBottom>
                        Open Feedback
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        label="General feedback about the platform"
                        value={openFeedback.feedbackPlatform}
                        onChange={(e) => openFeedback.setFeedbackPlatform(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        label="Feedback specifically about AI interventions"
                        value={openFeedback.feedbackAI}
                        onChange={(e) => openFeedback.setFeedbackAI(e.target.value)}
                        margin="normal"
                    />
                </Grid>
            </Grid>
        </Box >
    );
}