import { organizeCommentsByParent } from './dataHandlers';

export function formatPostForLLM(round, playerId) {
    const posts = round.get('posts') || [];
    const comments = round.get('comments') || [];
    const actions = round.get('actions') || [];
    const participants = round.get('participants') || [];

    const commentsByParent = organizeCommentsByParent(comments);

    const getUsername = (userId) => {
        if (userId === playerId) {
            return 'You';
        }
        const participant = participants.find(p => p.user_id === userId);
        return participant ? participant.username : 'Unknown';
    };

    const getLikes = (contentId) => {
        const likes = actions.filter(action => action.content_id === contentId && action.type === 'like');
        const likeTypes = likes.reduce((acc, action) => {
            const likeType = action.user_id === playerId ? `You (${action.like_type})` : action.like_type;
            acc[likeType] = (acc[likeType] || 0) + 1;
            return acc;
        }, {});
        return likeTypes;
    };

    const formatLikes = (likeTypes) => {
        return Object.entries(likeTypes).map(([type, count]) => `${type}: ${count}`).join(', ');
    };

    const formatComment = (comment, depth = 1) => { // Start depth at 1 for small indent
        const indent = ' '.repeat(depth * 2);
        const username = getUsername(comment.user_id);
        const likeTypes = getLikes(comment.content_id);
        const formattedLikes = formatLikes(likeTypes);
        let formattedComment = `${indent}${username}: ${comment.content}\n`;
        formattedComment += `${indent}  Likes: ${formattedLikes}\n`;

        const childComments = commentsByParent[comment.content_id] || [];
        const formattedChildComments = childComments.map(child => formatComment(child, depth + 1)).join('');
        return `${formattedComment}${formattedChildComments}`;
    };

    const formattedPosts = posts.map(post => {
        const username = getUsername(post.user_id);
        const likeTypes = getLikes(post.content_id);
        const formattedLikes = formatLikes(likeTypes);
        let result = `Post by ${username}:\n${post.content}\n`;
        result += `Likes: ${formattedLikes}\n`;

        const postComments = commentsByParent[post.content_id] || [];
        const formattedComments = postComments.map(comment => formatComment(comment)).join('');
        return `${result}${formattedComments}`;
    }).join('\n\n');

    return formattedPosts;
}
