const questionsKnowledgeAssessment = [
    "I have a good understanding of the causes and effects of climate change.",
    "I stay informed about current events and issues in US politics.",
    "I believe we should have more gun regulation.",
    "I support the implementation of renewable energy sources to combat climate change.",
    "I think climate change is one of the most pressing issues of our time.",
    "I think healthcare should be accessible to everyone regardless of their financial status.",
    "I believe in the necessity of vaccination to prevent disease outbreaks.",
    "I think social media platforms should do more to combat misinformation.",
    "I am concerned about the privacy implications of social media usage.",
    "I feel that social media often promotes unhealthy comparisons and lifestyles.",
    "I think social media influences people’s perceptions of reality.",
    "I trust AI-generated content to be accurate and reliable.",
    "I think AI should be regulated to prevent misuse and ensure ethical use.",
];

export { questionsKnowledgeAssessment };