import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { usePlayer, useGame, useStageTimer } from "@empirica/core/player/classic/react";
import { Button, Modal, Box, Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { IntroStepper } from "../components/Stepper";
import { DemographicInformation } from "./intro-elements/DemographicInformation";
import { SocialMediaUsage } from "./intro-elements/SocialMediaUsage";
import { KnowledgeAssessment } from "./intro-elements/KnowledgeAssessment";
import { useDemographicInfo, useSocialMediaInfo, useKnowledgeAssessment, allFieldsAssignedSocialMediaInfo, allFieldsAssignedKnowledgeAssessment, allFieldsAssignedDemographicInfo } from "./intro-elements/customHooks";
import { TaskIntroduction } from "./intro-elements/TaskIntroduction";
import { Consent } from "./intro-elements/Consent";
import { Container } from "@mui/material";
import { humanTimer } from "../components/Timer";

export function InitialSurvey({ }) {
    const player = usePlayer();
    const stageTimer = useStageTimer();
    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [agreed, setAgreed] = useState(false);
    const steps = ['Task Introduction', 'Consent', 'Demographic Information', 'Social Media Usage', 'Topic Interests and Opinions'];

    const demographicInfo = useDemographicInfo();
    const socialMediaInfo = useSocialMediaInfo();
    const knowledgeAssessmentInfo = useKnowledgeAssessment();

    function handleSubmit() {
        player.set("initialSurvey", {
            ...demographicInfo,
            ...socialMediaInfo,
            ...knowledgeAssessmentInfo,
        });
    }

    function handleCheckboxChange(setter) {
        return (option, checked) => {
            setter((prev) => {
                if (checked) {
                    return [...prev, option];
                } else {
                    return prev.filter((item) => item !== option);
                }
            });
        };
    }

    const containerRef = useRef(null);

    const scrollToTop = () => {
        if (containerRef.current) {
            containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        scrollToTop();
    }, [activeStep]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFinish = () => {
        handleSubmit();
        setOpen(true);
        // player.stage.set("submit", true);
    };

    const handleClose = () => {
        // Prevent closing the modal
    };

    const isStepValid = () => {
        switch (activeStep) {
            case 0:
                return true;
            case 1:
                return agreed;
            case 2:
                return allFieldsAssignedDemographicInfo(demographicInfo);
            case 3:
                return allFieldsAssignedSocialMediaInfo(socialMediaInfo);
            case 4:
                return allFieldsAssignedKnowledgeAssessment(knowledgeAssessmentInfo);
            default:
                return false;
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <TaskIntroduction />;
            case 1:
                return <Consent setAgreed={setAgreed} agreed={agreed} />;
            case 2:
                return <DemographicInformation {...demographicInfo} handleCheckboxChange={handleCheckboxChange} />;
            case 3:
                return <SocialMediaUsage {...socialMediaInfo} handleCheckboxChange={handleCheckboxChange} />;
            case 4:
                return <KnowledgeAssessment {...knowledgeAssessmentInfo} handleCheckboxChange={handleCheckboxChange} />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Container
            ref={containerRef}
            style={{
                minHeight: 'calc(100vh - 80px)',
                width: '100%',
                maxWidth: '1200px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                position: 'fixed',
                top: '80px',
                bottom: '0',
                left: '50%',
                transform: 'translateX(-50%)',
                padding: '20px',
            }}
        >
            <Box className="py-8 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <IntroStepper
                    activeStep={activeStep}
                    steps={steps}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    handleFinish={handleFinish}
                    isStepValid={isStepValid()}
                >
                    <Box component="form" className="mt-8 space-y-8 divide-y divide-gray-400">
                        {getStepContent(activeStep)}
                    </Box>
                </IntroStepper>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        borderRadius: '10px', // Rounded corners
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Subtle shadow
                        p: 4,
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 60,
                            height: 60,
                            borderRadius: '50%',
                            backgroundColor: 'lightgreen',
                            mb: 2
                        }}
                    >
                        <CheckCircleOutline sx={{ fontSize: 60, color: 'white' }} />
                    </Box>
                    <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                        Questionnaire Finished 🎉
                    </Typography>
                    <Typography id="modal-description">
                        You were quicker than average! Soon you will proceed to the next part of the experiment.
                    </Typography>
                    <Typography id="modal-description" variant="h6">
                        {stageTimer ? humanTimer(Math.round(stageTimer.remaining / 1000)) : 'Loading...'}
                    </Typography>
                </Box>
            </Modal>
        </Container>
    );
}