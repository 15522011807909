import React, { useEffect } from "react";
import { Box, FormControl, FormControlLabel, FormLabel, Grid, Checkbox, TextField, Typography, RadioGroup, Radio } from "@mui/material";
import { MultipleSelectionQuestion } from "../../components/Questionaire/MultipleSelectionQuestion";
import { SingleCategoricalQuestion } from "../../components/Questionaire/SingleCategoricalQuestion";

export function SocialMediaUsage({ socialMediaFrequency, setSocialMediaFrequency,
    socialMediaPlatforms, setSocialMediaPlatforms,
    engagementFrequency, setEngagementFrequency,
    handleCheckboxChange,
    otherPlatform, setOtherPlatform,
    socialInfluence, setSocialInfluence,
    conformity, setConformity,
    credibility, setCredibility,
    trust, setTrust
}) {

    const likertLabels = {
        1: 'Never',
        2: 'Rarely (1-2 times monthly)',
        3: 'Sometimes (1-2 times weekly)',
        4: 'Often (3-5 times weekly)',
        5: 'Daily',
        6: 'Prefer not to answer'
    };

    return (
        <Box sx={{ p: 3, mb: 6 }}>
            <Typography variant="h5" component="h3" gutterBottom>
                📱 Social Media Usage
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
                On this page, we want to know about how you use social media.
            </Typography>
            <Grid container spacing={5} sx={{ mt: 3 }}>
                <Grid item xs={12}>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">How often do you use social media?</FormLabel>
                        <RadioGroup
                            name="socialMediaFrequency"
                            value={socialMediaFrequency}
                            onChange={(e) => setSocialMediaFrequency(e.target.value)}
                        >
                            {["Multiple times a day", "Once a day", "A few times a week", "Once a week", "Rarely", "Never", "Prefer not to answer"].map((frequency) => (
                                <FormControlLabel key={frequency} value={frequency} control={<Radio />} label={frequency} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <MultipleSelectionQuestion
                        label="Which social media platforms do you use most frequently? (Select all that apply)"
                        name="socialMediaPlatforms"
                        values={socialMediaPlatforms}
                        options={["Facebook", "Twitter (X)", "Instagram", "LinkedIn", "TikTok", "Reddit", "Bluesky", "Mastodon", "Snapchat", "Other (please specify)", "Prefer not to answer"]}
                        onChange={handleCheckboxChange(setSocialMediaPlatforms)}
                        otherValue={otherPlatform}
                        setOtherValue={setOtherPlatform}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">How often do you actively interact (i.e. like, comment, share) with content related to the following topics on social media?</FormLabel>
                        <Box>
                            {["Climate change", "US politics", "Science"].map((topic) => (
                                <React.Fragment key={topic}>
                                    <FormLabel component="legend" sx={{ fontSize: '0.875rem', mt: 5 }}>{topic}</FormLabel>
                                    <Box sx={{ display: 'flex', mt: 3, mb: 3 }}>
                                        <RadioGroup
                                            name={`${topic}-frequency`}
                                            value={Object.keys(likertLabels).find(key => likertLabels[key] === engagementFrequency[topic]) || ""}
                                            onChange={(e) => {
                                                const selectedKey = e.target.value;
                                                const selectedLabel = likertLabels[selectedKey];
                                                setEngagementFrequency({ ...engagementFrequency, [topic]: selectedLabel });
                                            }}
                                        >
                                            {Object.keys(likertLabels).map((key) => (
                                                <FormControlLabel
                                                    key={key}
                                                    value={key}
                                                    control={<Radio />}
                                                    label={likertLabels[key]}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </Box>
                                </React.Fragment>
                            ))}
                        </Box>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="Do you recall changing your opinion based on interactions on social media?"
                        name="socialInfluence"
                        value={socialInfluence}
                        options={["Never", "Once", "A few times", "Several times", "Frequently", "Prefer not to answer"]}
                        onChange={(e) => setSocialInfluence(e.target.value)} // Ensure the state is updated correctly
                    />
                </Grid>
                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="Do you tend to agree with the opinions you see on social media?"
                        name="conformity"
                        value={conformity}
                        options={["Never", "Rarely", "Sometimes", "Often", "Always", "Prefer not to answer"]}
                        onChange={(e) => setConformity(e.target.value)} // Ensure the state is updated correctly
                    />
                </Grid>
                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="How credible do you find information shared by other users on social media?"
                        name="credibility"
                        value={credibility}
                        options={["Not credible at all", "Slightly credible", "Moderately credible", "Very credible", "Extremely credible", "Prefer not to answer"]}
                        onChange={(e) => setCredibility(e.target.value)} // Ensure the state is updated correctly
                    />
                </Grid>
                <Grid item xs={12}>
                    <SingleCategoricalQuestion
                        label="How much do you trust information from social media compared to traditional media?"
                        name="trust"
                        value={trust}
                        options={["Much less", "Less", "About the same", "More", "Much more", "Prefer not to answer"]}
                        onChange={(e) => setTrust(e.target.value)} // Ensure the state is updated correctly
                    />
                </Grid>
            </Grid>
        </Box>
    );
}